import React from 'react';
import './About.css';

import NotificationsIcon from '@material-ui/icons/Notifications';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
const Card = ({ title, content, icon }) => {
  return (
    <div className="card">
      <div className="card-icon">
        {icon }
      </div>
      <h2 style={{ color: 'white' }}>{title}</h2>
      <p style={{ color: 'white' }}>{content}</p>
    </div>
  );
};

const CardsPage = () => {
  const cards = [
    {
      title: 'SECURED BUSINESS',
      content: 'You can trust us to look after your money',
      icon: <NotificationsIcon style={{ fontSize: '3rem', color: 'white' }} /> 
    },
    {
      title: 'HELPS TO GROW',
      content: 'We bring in the opportunities that you can avail.',
      icon: <CalendarTodayIcon style={{ fontSize: '3rem', color: 'white' }} /> 

    },
    {
      title: 'FINANCIAL ADVICE',
      content: 'We provide advice to manage your business.',
      icon: <AccountBoxIcon style={{ fontSize: '3rem', color: 'white' }} /> 

    },
    {
      title: 'AFFORDABLE',
      content: 'We provide cost-effective services for our clients',
      icon: <AttachMoneyIcon style={{ fontSize: '3rem', color: 'white' }} /> 

    },
  ];

  return (
    <>
      <div id="about" className="about-container">
        <div className="about-heading">WHY CHOOSE US</div>
        <div className="cardContainer">
          {cards.map((card, index) => (
            <Card
              key={index}
              icon={card.icon}
              title={card.title}
              content={card.content}
            />
          ))}
        </div>
      </div>
      <div className="another-container">Hellllll</div>
    </>
  );
};

export default CardsPage;
