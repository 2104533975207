import React, { useState } from 'react';
import './Service.css'; // Import the CSS file
import img1 from "../../assets/notes.webp";
import img2 from "../../assets/notes2.webp";
import img3 from "../../assets/book.webp"
import img4 from "../../assets/search.webp"
import img5 from "../../assets/vision.webp"
import img6 from "../../assets/market.webp"


const ServiceCard = ({ title, description,image }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClick = () => {
    setIsHovered(!isHovered);
  };
  const cardStyle = {
    border: '1px solid black',
    padding: '30px',
    margin: '5px',
    flex: 1,
    width: '250px',
    height: '270px',
    background: isHovered ? '#4CAF50' : '#1c1c1c',
    transition: 'background-color 0.3s',
  };

  const titleStyle = {
    color: isHovered ? '#000' : '#ecf9ff',
  };

  const descriptionStyle = {
    color: isHovered ? '#000' : '#ecf9ff',
  };

  return (
    <div
    style={cardStyle}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={handleClick}
    className={isHovered ? 'card-hovered' : ''}
  >
    <div className="service-avatar-container">
        <img src={image} className="avatar" />
      </div>
      <h3 style={titleStyle}>{title}</h3>
      <p style={descriptionStyle}>{description}</p>
      
    </div>
  );
};

const Service = () => {
    const services = [
        {
          title: 'VISION TO PLAN',
          description: 'Coordinate complete resource taxation relationships through leading niches. Visualise customer-centric convergence without disruptive ROI.',
          image: img1,
        },
        {
          title: 'PLAN TO MARKET',
          description: 'Change management podcasting works within workflows to establish a framework. Take the main indicators offline to maximise the long tail',
          image: img2,
        },
        {
          title: 'MARKET TO GROWTH',
          description: 'Reliably innovate with detailed internal or "organic" sources while maintaining high standards for web availability. Dynamic scaling stands the test of time.',
          image: img3,
        },
      ];
    
      const services2 = [
        {
          title: 'GROWTH TO VISION',
 
          description: 'Dramatically visualise consumer directed convergence without modern ROI. Completely synergize useful resource relationships through the ultimate area',
          image: img4,
        },
        {
          
            title: 'VISION TO MARKET',
            image: img5,
          description: 'Imagine high-quality intellectual capital without collaboration and sharing of ideas. Portals for the installed base advertise comprehensively by product.',
        },
        {
          title: 'MARKET TO PLAN',
          image: img6,
          description: 'Develop one-on-one customer service with strong ideas in a professional manner. innovative resource levelling service for modern customer service.',
        },
      ];

  return (
    <>
    <div id='service' className="diff-container">
    <div className="heading">WHY WE ARE DIFFERENT?</div> 
    <div className="description">We provide insight and advice on financial planning, tax compliance and investment strategies. Our knowledge and skills can help companies save money, avoid costly mistakes and make smart decisions for the future. No matter how big or small your business, there is always the perfect chartered accountant for your business. We’ll assist you thrive and beat your competitors, enabling you to stay ahead of the competition.</div> 

    </div>
    <div className="service-container"> 
      <div className="heading">SOME OF OUR BEST SERVICES</div> 

      <div className="card-container"> {/* Use the CSS class for the card container */}
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            image={service.image}
            title={service.title}
            description={service.description}
            
          />
        ))}
      </div>
      <div className="card-container"> {/* Use the CSS class for the card container */}
        {services2.map((service, index) => (
          <ServiceCard
            key={index}
            image={service.image}

            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </div>
    </>
  );
};

export default Service;
