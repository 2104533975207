import React from 'react';
import "./Slide.css";  
import img from "../../assets/2.png";


const Slide = () => {
  

  return (
    <div id="home"className="slide-container">
      <div className="imageContainer">
          <img src={img} className="slide-imageStyle" />
        </div>
            <div className='text-container'>
              <span style={{ fontWeight: 'bold', fontSize: '38px' ,color:'#fe8237'}}>
                WE WILL MANAGE
              </span>
              <br /><br />
              <span style={{ fontWeight: 'bold', fontSize: '38px' }}>
               Financial Planning for You
              </span>
              <br /><br />
              We are providing all tax &amp; compliance services in India whether online or offline using the most secure technology with upfront transparent pricing approach and helping businesses to grow.
              <br /><br />
              <a style={{
  backgroundColor: '#fe8237',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  fontSize: '16px',
  textDecoration: 'none'
}} href='#contact'>
  Contact Us
</a>
            </div>
          </div>
       
   
  );
};

export default Slide;
