
import React from 'react';
import './Testimonial.css'; // Add your custom styling
import img1 from "../../assets/Neetu.jpg";
import img2 from "../../assets/Saif.jpg";
import img3 from "../../assets/Tushar.jpg"
import img4 from "../../assets/Tariq.jpg"


const testimonials = [
  {
    id: 1,
    name: 'Neetu Arora',
    position: 'Manager, Sapphire International',
    quote: 'Demonstrated unparalleled financial expertise, aiding our business with strategic insights and compliance. Their professionalism and tailored solutions were crucial in our success. Highly recommend their services for seamless financial management.',
    image: img1,
  },
  {
    id: 2,
    name: 'Saif Khan',
    position: 'Founder, Auditree Healthcare',
    quote: 'Their financial expertise and strategic insights were invaluable for our business. They displayed utmost professionalism, ensuring compliance and offering tailored solutions. Highly recommend their services for proficient financial management and guidance.',
    image: img2,
  },
  {
    id: 3,
    name: 'Tushar Taneja',
    position: 'CEO, Alanco Technologies',
    quote: 'Our CA provided top-notch financial guidance, ensuring compliance and optimal strategies. Professionalism, adaptability, and insightful advice set them apart. Highly recommend their expertise for sound financial management and strategic growth.',
    image: img3,
  },
  {
    id: 4,
    name: 'Tariq Jamal',
    position: 'Entrepreneur',
    quote: 'Expert financial advice and meticulous record-keeping. Professionalism, adaptability and insightful advice set them apart.Their proactive approach and compliance expertise were impressive. A trustworthy partner for any business seeking sound financial management .',
    image: img4,
  },
];

const TestimonialCard = ({ name, position, quote, image }) => {
  return (
    <div className="testimonial-card">
      <div className="avatar-container">
        <img src={image} alt={`${name} avatar`} className="avatar" />
      </div>
      <div className="quote">{quote}</div>
      <div className="info">
        <div className="name">{name}</div>
        <div className="position">{position}</div>
      </div>
    </div>
  );
};

const Testimonial = () => {
  return (
    <div className="App">
      <div className="testimonial-container">
        {testimonials.map((testimonial) => (
          <TestimonialCard
            key={testimonial.id}
            image={testimonial.image}
            name={testimonial.name}
            position={testimonial.position}
            quote={testimonial.quote}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
