
import React from 'react';
import './Footer.css'; 
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import img from "../../assets/Logo.png";
import doc from "../../assets/FAQs, Terms of Use & Privacy Policy.docx"

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-section company">
          {/* <h3>SUFIYAN KHAN ASSOCIATES</h3> */}
          <img src={img} style={{width:'100%',objectFit: 'cover'}}/>
          <p><a href="#home">Home</a></p>
          <p><a href="#about">About Us</a></p>
          <p><a href="#service">Services</a></p>

        </div>

        <div className="footer-section useful-links">
          <h3>Useful Links</h3>
          <p><a target='blank' href="https://www.incometax.gov.in/iec/foportal/">Income Tax</a></p>
          <p><a target='blank' href="https://www.gst.gov.in/">Good and Services Tax</a></p>
          <p><a target='blank' href="https://www.dgft.gov.in/CP/">DGFT</a></p>
          <p><a target='blank'  href="https://www.mca.gov.in/content/mca/global/en/home.html">MCA</a></p>
        </div>
        <div className="footer-section useful-links">
          <h3>Useful Links</h3>
          <p><a target='blank' href="https://www.icai.org/ ">ICAI</a></p>
          <p><a href={doc} target="_blank" rel="noopener noreferrer">Terms of Service</a></p>
          <p><a href={doc} target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
          <p><a href={doc} target="_blank" rel="noopener noreferrer">FAQ</a></p>

        </div>

        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p>Email: <a href="casufiyankhan@hotmail.com">casufiyankhan@hotmail.com</a></p>
          <p>Phone: <a href="tel:9582867902">+91 9582867902</a></p>
          <p>Address: R-286, Shop No. 1, Sir Syed Road, Zakir Nagar, Okhla, Delhi - 110025</p>
        </div>
      </div>
      <div className="footer-icons">
        <a target='blank' href="https://www.instagram.com/s_k_and_associates/">
          <InstagramIcon />
        </a>
  
        <a target='blank' href="https://twitter.com/sufiyan_sak">
        <i class="fa-brands fa-x-twitter"></i>    
         </a>
        <a  target='blank' href="https://www.linkedin.com/in/ca-sufiyan-ahmed-khan-68a20314b/">
          <LinkedInIcon />
        </a>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2023. All Right Reserved</p>
 
      </div>
    </div>
  );
};

export default Footer;
