import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
// import { faMapMarkerAlt, faPhone, faEnvelope, faClock } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MailIcon from '@material-ui/icons/Mail';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const handleWhatsAppClick = () => {
    const phoneNumber = "1234567890";

    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = 'service_ndzofde'; 
    const templateID = 'template_bnh2dcm'; 
    const publicKey = '1VTUxg7YbANTE7AQg'; 

    emailjs
      .send(serviceID, templateID, formData, publicKey)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          name: '',
          number: '',
          email: '',
          message: '',
        });
        toast.success('Email sent successfully!');
      })
      .catch((error) => {
        console.error('Email sending error:', error);
        toast.error('Failed to send email. Please try again.');
      });
  };

  return (
    <div className="contact-container" id="contact">
          <div className="contact-infos">
        <h2>Contact Info</h2>
        <div className="info-item">
        < LocationOnIcon style={{ fontSize: '2rem', color: 'white' }} />
                  <span>Address:</span>
          <p>R-286, Shop No. 1, Sir Syed Road, Zakir Nagar, Okhla, Delhi - 110025</p>
        </div>
        <div className="info-item">
        < LocalPhoneIcon style={{ fontSize: '2rem', color: 'white' }} />
          <span>Phone:</span>
          <p>+91 9582867902 , +91 7701841270</p>
        </div>
        <div className="info-item">
        < MailIcon style={{ fontSize: '2rem', color: 'white' }} />
          <span>Email:</span>
          <p>casufiyankhan@hotmail.com</p>
        </div>
        <div className="info-item">
        < AccessTimeIcon style={{ fontSize: '2rem', color: 'white' }} />
          <span>Working Hours:</span>
          <p>Mon to Sat – 10:00am to 7:00pm</p>
        </div>
      </div>
      <div className="contact-form">
        <h2>Get in Touch</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleInputChange}
            className="inputField"
            required
          />
          <input
            type="text"
            name="number"
            placeholder="Number"
            value={formData.number}
            onChange={handleInputChange}
            className="inputField"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            className="inputField"
            required
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleInputChange}
            className="textareaField"
          ></textarea>
          <button type="submit" className="button buttonBlue">
            Send your Message
          </button>
        </form>
        {/* <button onClick={handleWhatsAppClick} className="button buttonGreen">
          WhatsApp Us
        </button> */}
      </div>
      <ToastContainer />

    </div>
  );
};

export default Contact;
