import logo from './logo.svg';
import './App.css';
import MainPage from './components/MainPage/MainPage';
import Service from './components/Services/Service';
import About from './components/AboutUs/About';
import PricingPlan from './components/Pricing/Pricing';
import Testimonial from './components/Testimonal/Testimonial';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact Us/Contact';

function App() {
  
  
  return (
    <div className="App">
      {/* a */}
    <MainPage/>
    <Service/>
    <About/>
    {/* <PricingPlan/> */}
    <Contact/>
    <Testimonial/>
   
    <Footer/>
    

    </div>
  );
}

export default App;

